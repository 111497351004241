import styled from "styled-components"
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AddOnsGrid({}) {
    return (
        <>
            <div style = {{fontSize: "16px"}} className="food-menu-heading">ADD ONS</div>
            <TeaStyled>
                <div>
                    <span>EXTRA COFFEE SHOT / 8</span>
                </div>
                <div>
                    <span>ADD AVOCADO / 16</span>
                </div>
                <div>
                    <span>EXTRA EGG / 4</span>
                </div>
                <div>
                    <span>EXTRA CHEESE / 4</span>
                </div>
                <div style = {{marginBottom: "50px"}}>
                    <span>EXTRA TURKEY / 6</span>
                </div>                
            </TeaStyled>
            <SocialMediaContainer>
                <a  target = "_blank" href = "https://www.instagram.com/whitecoffeespot/">
                    <FontAwesomeIcon icon = {faInstagram} style = {{fontSize: "20px" , '--fa-animation-iteration-count': '1'}} flip/>
                </a>
            </SocialMediaContainer>
        </>
    )
}

function AddOnsGridAr({}) {
    return (
        <>
            <div className="food-menu-heading-ar">الإضافات</div>
            <TeaArStyled>
                <div>
                     <span>شوت قهوة اضافية / ٨</span>
                </div>
                <div>
                    <span>إضافة الأفوكادو / ١٦</span>
                </div>
                <div>
                    <span>بيضة إضافية / ٤</span>
                </div>
                <div>
                    <span>جبنة إضافية / ٤</span>
                </div>
                <div>
                    <span>ديك رومي إضافي / ٦</span>
                </div>                
            </TeaArStyled>
            <SocialMediaContainer>
                <a  target = "_blank" href = "https://www.instagram.com/whitecoffeespot/">
                    <FontAwesomeIcon icon = {faInstagram} style = {{fontSize: "20px" , '--fa-animation-iteration-count': '1'}} flip/>
                </a>
            </SocialMediaContainer>
        </>
    )
}

const TeaStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

const TeaArStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

const SocialMediaContainer = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: 60px;

    img {
        position: absolute;
        inset:0;
        width:40px;
        height: 40px
    }
`

export  {
    AddOnsGrid,
    AddOnsGridAr,
}