import {Grid} from "@mui/material"
import { mainFive, mainFour, mainOne, mainThree, mainTwo, mainOneAr, mainTwoAr, mainThreeAr, mainFourAr, mainFiveAr } from "../../../data/baronCategories/baronMain";
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer, ItemDetailsStyled, ItemDetailsArStyled,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "./generalStyles";

function MainGrid({triggerLanguage}) {

    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderStyled>
                        main
                    </CategoryHeaderStyled>
                    {/* <CategorySubHeaderStyled>
                        Sub Header one
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            mainOne.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header two
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainTwo.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header three
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainThree.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header four
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainFour.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header five
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainFive.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }

    if(triggerLanguage == "ar") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                        main arabic
                    </CategoryHeaderArStyled>
                    <CategorySubHeaderArStyled>

                    </CategorySubHeaderArStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                            {
                                mainOneAr.map(({name, details, price, img_path}) => {
                                    return (
                                        <Grid item xs = {6} sm = {6}>
                                            <ItemContainerStyled>
                                                <ItemImageContainer>
                                                    <img src = {img_path} />
                                                </ItemImageContainer>
                                                <ItemBoxTwoStyled>
                                                    <ItemPriceContainerAr>
                                                        <div> {price} </div>
                                                        <div><span>ر.ق</span></div>   
                                                    </ItemPriceContainerAr>
                                                    <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                        <div>
                                                            <ItemNameArStyled>
                                                                {name}
                                                            </ItemNameArStyled>
                                                            <ItemDetailsArStyled>{details}</ItemDetailsArStyled>
                                                        </div>
                                                    </ItemDetailsContainer>    
                                                </ItemBoxTwoStyled>
                                            </ItemContainerStyled>
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header two
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainTwoAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div> {price} </div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header two
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainThreeAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header two
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainFourAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    {/* <CategorySubHeaderStyled>
                        sub header two
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {2}>
                        {
                            mainFiveAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }
}

//move all these styles to a general style for all items


export default MainGrid;