let sweetsOne = [

    {
        name: "MERRY CREAM , ",
        details: " OLIVE OIL MALDON SALT",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/01 Merry cream.jpg"
    },

    {
        name: `"DATE" , `,
        details: " STEAMED CAKE, MILK ICE CREAM, SALTED CARAMEL",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/02 Date.jpg"
    },

    {
        name: "PINOLI , ",
        details: " TART, BARAZEK, PINE NUT, TAHINI, SESAME CRÉMEUX",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/03 Pinoli.jpg"
    },

    {
        name: "CHOCOLATE , ",
        details: " FLOURLESS, CRÈME FRAÎCHE CREAM, MALDON",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/04-Chocolate.jpg"
    },

    {
        name: "SUNDAE , ",
        details: " CARAMALIZED ALMONDS, DULCE DE LECHE",
        price: "50",
        img_path: "/baron-menu-designs/Sweets Pics/05-Sundae.jpg"
    },
]

let sweetsOneAr = [

    {
        name: `"التمر"، `,
        details: "كعكة على البخار، آيس كريم الحليب، الكراميل المملح",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/gray color.jpg",
    },

    {
        name: "ميري كريم، ",
        details: "زيت زيتون، ملح",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/01 Merry cream.jpg",
    },

    {
        name: "شوكولاتة، ",
        details: "بدون دقيق، كريمة طازجة، مالدون",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/04-Chocolate.jpg",
    },

    {
        name: "بينولي، ",
        details: "تارت، برازق، صنوبر، طحينة، كريمة السمسم",
        price: "45",
        img_path: "/baron-menu-designs/Sweets Pics/03 Pinoli.jpg",
    },

    {
        name: "سانداي، ",
        details: "قرع مكرمل، دولسي دي ليتشي",
        price: "50",
        img_path: "/baron-menu-designs/Sweets Pics/05-Sundae.jpg",
    },
]

export {
    sweetsOne,
    sweetsOneAr,
}