import { Grid } from "@mui/material";

function DessertGrid({}) {
    return (
        <>
            <div className="food-menu-heading">DESSERT</div>
            <Grid container spacing = {2} rowSpacing = {4}>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-01.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-02.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-03.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-04.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-09.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-06.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-07.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/Dessert-08.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert-spinach danish.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert-tomato danish.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert-strawberry danish.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert- zaatar croissant.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert- safron mango dream cake.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert- banana bread.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Dessert JPG/dessert-vanilla flan.jpg"/>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

function DessertGridAr() {
    return (
        <>
            <div className="food-menu-heading-ar">حلويات</div>
            <Grid container spacing = {2} rowSpacing = {4}>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-01.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-02.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-03.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-04.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert-brown brownie-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-06.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-07.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/Dessert-AR-08.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert-spinach danish-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert-tomato danish-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert-strawberry danish-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert-zaatar croissant-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert- safron- ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert- banana bread- ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Dessert Ar JPG/dessert-vanila-ar.jpg"/>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default DessertGrid;

export {
    DessertGrid,
    DessertGridAr,
}