
import './App.css';
import {BrowserRouter as Router, Routes as Switch, Route, useLocation} from "react-router-dom";
import {Lsd, FoodMenu, ShaqabMenu, WhiteCoffeeSpotMenu, LtbMenu, ChariotMenu, AfterNoonTeaMenu, ErgonMenu, LeBleuMenu, LeBleuMenuEv, LsdAbout, LsdContact, LsdIntroPage, BaronMenu, LeBleuMenuNew, HabraMenu, LunchroomMenu, WcsNewMenu, FiveAm} from "./pages";
import {TestAdmin, UpdateItem, EditItem, AdminLogin} from "./adminPages";
import { ScrollToTop} from './components';
import { AnimatePresence } from 'framer-motion';
import RequireAuth from './components/RequireAuth';
import {AddItemChariot, UpdateItemChariot, EditItemChariot} from "./adminPages/restaurants/chariot"


function App() {

  const location = useLocation();

  return (
    // <div className="App">
      <div className="layout">
          <ScrollToTop/>
          <AnimatePresence>
            <Switch location = {location} key = {location.pathname}>
                <Route exact path = "/" element = {<LsdIntroPage/>}/>
                <Route  path = "/home" element = {<Lsd/>}/>
                <Route path = "/about" element = {<LsdAbout/>}/>
                <Route path = "/contact-us" element = {<LsdContact/>}/>
                <Route path = "/restaurants/menus/shawarma-el-khebbez" element = {<FoodMenu/>}/>
                <Route path = "/restaurants/menus/shawarma-el-khebbez/shaqab" element = {<ShaqabMenu/>}/>
                {/* <Route path = "/restaurants/menus/white-coffee-spot" element = {<WhiteCoffeeSpotMenu/>}/> */}
                <Route path = "/restaurants/menus/white-coffee-spot" element = {<WcsNewMenu/>}/>
                <Route path = "/restaurants/menus/le-train-bleu" element = {<LtbMenu/>}/>
                <Route path = "/restaurants/menus/chariot" element = {<ChariotMenu/>}/>
                <Route element = {<RequireAuth requiredRole= "chariot" />}>
                  <Route path = "/restaurants/menus/chariot/admin/update-item" element  = {<UpdateItemChariot/>}/>
                  <Route path = "/restaurants/menus/chariot/admin/add-item" element  = {<AddItemChariot/>}/>
                  <Route path = "/restaurants/menus/chariot/admin/edit-item/:id" element  = {<EditItemChariot/>}/>
                </Route>
                <Route path = "/restaurants/menus/ergon" element = {<ErgonMenu/>}/>
                {/* <Route path = "/restaurants/menus/le-train-bleu/Afternoon-tea" element = {<AfterNoonTeaMenu/>}/> */}
                <Route path = "/restaurants/menus/le-bleu-backup" element = {<LeBleuMenu/>}/>
                <Route path = "restaurants/menus/le-bleu" element = {<LeBleuMenuNew />}/>
                <Route path = "/restaurants/menus/le-bleu/admin/login" element = {<AdminLogin/>}/>
                <Route element = {<RequireAuth requiredRole = "le-bleu"/>}>
                  <Route path = "/restaurants/menus/le-bleu/admin/update-item" element  = {<UpdateItem/>}/>
                  <Route path = "/restaurants/menus/le-bleu/admin/add-item" element = {<TestAdmin/>}/>
                  <Route path = "/restaurants/menus/le-bleu/admin/edit-item/:id" element = {<EditItem/>}/>
                </Route>
                <Route path = "restaurants/menus/baron" element =  {<BaronMenu/>} />
                <Route path = "restaurants/menus/habra" element = {<HabraMenu/>}/>
                <Route path = "restaurants/menus/five-am" element = {<FiveAm/>}/>
                <Route path = "restaurants/menus/lunchroom" element = {<LunchroomMenu/>}/>
                <Route path = "/restaurants/menus/le-bleu-event" element = {<LeBleuMenuEv/>}/>
                <Route path = "/restaurants/menus/login" element = {<AdminLogin/>}/>
            </Switch>
          </AnimatePresence>
      </div>
    // </div>
  );
}

export default App;

