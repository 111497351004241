import { MenuPageStyled, WhiteCoffeeSpotPageStyled, WhiteCoffeeSpotPagrArStyled} from "../styles";
import { whiteCoffeeSpotMenu, whiteCoffeeSpotMenuAr } from "../data/foodMenuCategories";
import { BeveragesGrid, BeveragesGridAr, BreakfastGrid, BreakfastGridAr, DessertGridAr,  DessertGrid, SaladsGrid, SaladsGridAr,  SandwichesGrid, SandwichesGridAr, AddOnsGrid, AddOnsGridAr} from "../components/MenuGrids/wcsMenu";
import {useEffect, useState} from "react";
import styled from "styled-components";
import $ from 'jquery';

function WhiteCoffeeSpotMenu({}) {

    const [triggerLanguage, setTriggerLanguage] = useState('en');
    const [triggerCoverMenu, setTriggerCoverMenu] = useState(true);


    function handleTriggerMenuFunction(language) {
        switch(language) {
            case "en":
                setTriggerLanguage("en");
                setTriggerCoverMenu(false);
            break;

            case "ar":
                setTriggerLanguage("ar");
                setTriggerCoverMenu(false);
            break;
        }
    }

    function handleTriggerMenuFunctionNavBar(language) {
        switch(language) {
            case "en":
                setTriggerLanguage("en");
            break;

            case "ar":
                setTriggerLanguage("ar");

        }
    }

    useEffect(() => {

        const sections = document.querySelectorAll("section");
        
        const navLiEn = document.querySelectorAll("nav .wcsNavBar ul li");
        const liImgEn = document.querySelectorAll("nav .wcsNavBar ul li img");
        const animateClasses = ["animate__animated", "animate__bounceIn"];

        const navLiAr = document.querySelectorAll("nav .wcsNavBarAr ul li");
        const liImgAr = document.querySelectorAll("nav .wcsNavBarAr ul li img");
        
        // if(triggerCoverMenu == "false") {
            
        // }
        // var navBar = $('.wcsNavBar').offset().top;

        const handleScroll = event => {
            // var currentScroll = window.pageYOffset;

            //------check this nav bar later ----------
            // if(currentScroll >= navBar)  {
            //     $('.wcsNavBar').css({
            //         position: 'fixed',
            //         top: '0',
            //         left: '0',
            //         padding: '0px 18px',
            //     })
            // } else {
            //     $('.wcsNavBar').css({
            //         position: 'static',
            //         padding: '0px 0px',
            //     })
            // }
            //-------------------------------------------
            
            //english menu
            var current = "";
            sections.forEach((section) => {
                const sectionTop = section.offsetTop; //how far the section is from top
                if (window.pageYOffset  >= sectionTop - 60) {
                    current = section.getAttribute("id");
                }
            });
            navLiEn.forEach((li) => {
                li.classList.remove("active");
                if(li.classList.contains(current)) {
                    //get the list id and set the image with the same id to the bouncing ball
                    liImgEn.forEach((img) => {
                        if(img.getAttribute("id") == li.getAttribute("id")) {
                            img.classList.add(...animateClasses);
                        }
                        else {
                            img.classList.remove(...animateClasses);
                        }
                    })
                    li.classList.add("active");
                }
            });

            //arabic menu
            var currentAr = "";
            sections.forEach((section) => {
                const sectionTop = section.offsetTop; //how far the section is from top
                if (window.pageYOffset  >= sectionTop - 60) {
                    currentAr = section.getAttribute("id");
                }
            });
            navLiAr.forEach((li) => {
                li.classList.remove("active");
                if(li.classList.contains(currentAr)) {
                    //get the list id and set the image with the same id to the bouncing ball
                    liImgAr.forEach((img) => {
                        if(img.getAttribute("id") == li.getAttribute("id")) {
                            img.classList.add(...animateClasses);
                        }
                        else {
                            img.classList.remove(...animateClasses);
                        }
                    })
                    li.classList.add("active");
                }
            });

        }

        window.addEventListener("scroll", handleScroll);
        window.scrollTo({top: 0, left: 0, behavior: "auto"})
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [triggerCoverMenu, triggerLanguage])

    //wcs menu cover trigger
    if(triggerCoverMenu == true) {
        return (
            <>
                <MenuCoverContainerStyled>
                    <img src = "/wcs-menu-designs/wcs-coverMenu.jpg" alt = "white coffe spot cover menu"/>
                    <TriggerLanguageButtonsStyled>
                        <div>
                            <button style = {{backgroundColor: "transparent", border: "none", color: "black"}} onClick = {() => handleTriggerMenuFunction("en")}>
                                <span style = {{fontWeight: "bold", fontFamily: "Brandon Grotesque"}}>ENGLISH</span>
                            </button>
                        </div>
                        <VerticalLineStyled/>
                        <div style = {{lineHeight: "40px", borderLeft: "5px solid black"}}></div>
                        <div style = {{marginTop: "-10px"}}>
                            <button style = {{backgroundColor: "transparent", border: "none", color: "black", fontFamily: "DIN Next LT Arabic", fontWeight: "bold"}} onClick = {() => handleTriggerMenuFunction("ar")}>
                                عربي 
                            </button>
                        </div>
                    </TriggerLanguageButtonsStyled>    
                </MenuCoverContainerStyled>
            </>
        )
    }


    //renders the english menu
    if(triggerLanguage == 'en') {
        return (
            <>
            <WhiteCoffeeSpotPageStyled>
                {/* <Test>
                    <img src = "/sek-shaqab-menu-designs/sek-menu-cover-shaqab.jpg"/>
                </Test> */}
                <MenuCoverWrapperStyled>
                <nav>
                        <div className = "wcsNavBar">
                            <ul style={{paddingInlineStart: "0"}}>
                                {
                                    whiteCoffeeSpotMenu.map(({elementId, refNo, category, className, animateClassName}) => {
                                        return (
                                            <>
                                            <li id = {elementId} className = {className}>
                                            <BlueDotStyled>
                                                    <img id = {elementId} className = {animateClassName} src = "/wcs-menu-designs/blue-dot.jpg" href = "white coffee spot blue dot logo"/>
                                                   
                                                </BlueDotStyled>
                                            <a  href = {refNo}>
                                                    {category}
                                                    </a>
        
                                            </li>
                                        </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <WcsTestStyled>
                            <div style = {{marginTop: "-2px", marginLeft: "5px"}} onClick = {() => handleTriggerMenuFunctionNavBar("ar")}>
                                <span style = {{fontFamily: "Dancing Script", fontWeight: "500", fontSize: "15px" ,cursor: "pointer"}}>ع</span>
                            </div>
                        </WcsTestStyled>
                    </nav>
                    </MenuCoverWrapperStyled>
                <div>
                <section  id = "category_1">
                    <BreakfastGrid/>
                </section>
                <section  id = "category_2">
                <SandwichesGrid/>
                </section>
                <section  id = "category_3">
                <SaladsGrid/>
                </section>
                <section  id = "category_4">
                    <DessertGrid/>
                </section>
                <section  id = "category_5">
                    <BeveragesGrid/>
                </section>
                <section  id = "category_6">
                    <AddOnsGrid/>
                </section>
                </div>
                <CopyRightStyled>
                        <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                            <img src = "/Developed-By-LSD.svg"/>
                        </a>
                </CopyRightStyled>
                </WhiteCoffeeSpotPageStyled>
            </>
        )
    }
    // -------------------------------------------------------------------

    //renders the arabic menu
    if (triggerLanguage == 'ar') {
        return (
            <>
            <WhiteCoffeeSpotPagrArStyled>
                {/* <Test>
                    <img src = "/sek-shaqab-menu-designs/sek-menu-cover-shaqab.jpg"/>
                </Test> */}
                <MenuCoverWrapperStyled>
                <nav>
                        <div className = "wcsNavBarAr">
                            <ul>
                                {
                                    whiteCoffeeSpotMenuAr.map(({elementId, refNo, category, className, animateClassName}) => {
                                        return (
                                            <>
                                            <li id = {elementId} className = {className}>
                                            <BlueDotStyled>
                                                    <img id = {elementId} className = {animateClassName} src = "/wcs-menu-designs/blue-dot.jpg" href = "white coffee spot blue dot logo"/>
                                                   
                                                </BlueDotStyled>
                                            <a  style = {{fontSize: "14px"}} href = {refNo}>
                                                    {category}
                                                    </a>
        
                                            </li>
                                        </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </nav>
                    <WcsTestArStyled>
                        <div style = {{marginTop: "4px"}} onClick = {() => handleTriggerMenuFunctionNavBar("en")}>
                            <span style = {{fontSize : "14px", fontFamily: "Brandon Grotesque", cursor: "pointer"}}>En</span>
                        </div>
                    </WcsTestArStyled>
                    </MenuCoverWrapperStyled>
                <div>
                <section style = {{padding: "0px 18px"}}id = "category_7">
                    <BreakfastGridAr/>
                </section>
                <section style = {{padding: "0px 18px"}} id = "category_6">
                <SandwichesGridAr/>
                </section>
                <section style = {{padding: "0px 18px"}} id = "category_5">
                <SaladsGridAr/>
                </section>
                <section  style = {{padding: "0px 18px"}} id = "category_4">
                    <DessertGridAr/>
                </section>
                <section style = {{padding: "0px 18px"}} id = "category_2">
                    <BeveragesGridAr/>
                </section>
                <section style = {{padding: "0px 18px"}} id = "category_1">
                    <AddOnsGridAr/>
                </section>
                </div>
                <CopyRightStyled>
                        <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                            <img src = "/Developed-By-LSD.svg"/>
                        </a>
                </CopyRightStyled>
                </WhiteCoffeeSpotPagrArStyled>
            </>
        )
    }
}

const BlueDotStyled = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        height: 30px;
        width: 30px;
    }
`

const MenuCoverContainerStyled = styled.div`
    position: fixed;
    width: 100vw;
  
    img {
        position: absolute;
        inset: 0;
        width: 100%;
    }
`

const MenuCoverWrapperStyled = styled.div`
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 82px;
    background-color: white;
`

const TriggerLanguageButtonsStyled = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px;
    width: 70px;
    height: 70px;
    top: 525px;
    left: 110px;
`
const VerticalLineStyled = styled.div`
    border-left: 1px solid black;
    height: 25px;
    margin-left: 6px;
    margin-top: 4px;
`

const WcsTestStyled = styled.div`
    position: fixed;
    inset: 0px;
    /* left: 320px; */
    left: 90%;
    top: 30px;
    height:30px;
    width: 10vw;
`

const WcsTestArStyled = styled.div`
    position: fixed;
    inset: 0;
    display: flex;
    gap: 3px;
    top: 30px;
    height: 30px;
    width: 10vw;
    margin-left: 14px;
`

const NavBarVerticalLineStyled = styled.div`
    border-left: 2px solid black;
    height: 20px;;
`

const CopyRightCoverStyled = styled.div`
    position: fixed;
    height: 70px;
    top: 575px;
    /* padding: 40px 0px; */
    
    width: 100%;
    img {
        width: 60%;
    }
`

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    width: 100%;
    img {
        width: 45%;
    }
`

export default WhiteCoffeeSpotMenu;