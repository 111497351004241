let aperitifOne = [

    {
        name: "ROSSO , ",
        details: " LONDON DRY, APERITIF ITALIAN ORANGE ROSSO",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/01 Rosso.jpg"
    },

    {
        name: "FLUFFY FIZZ , ",
        details: " LEMON, LIME, CHERRY JUICE, ORANGE SELTZER",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/gray color.jpg"
    },

    {
        name: "BLACKBERRY , ",
        details: " COCONUT MILK, VANILLA SELTZER",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/03 Blackberry.jpg"
    },

    {
        name: "LEMONADE , ",
        details: " SUMAC BEETROOT SYRUP, LEMON, SUMAC, SELTZER",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/04 Lemonade.jpg"
    },

    {
        name: "HAWAI , ",
        details: " BLUEBERRY, LEMON, ROSE, LYCHEE SELTZER",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/05 huwaii.jpg"
    },

    {
        name: "BEACH , ",
        details: " GRENADINE ROSE, LIME SELTZER",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/06 Beach.jpg"
    },

    {
        name: "ZA’ATAR , ",
        details: " LONDON DRY, ZA’ATAR SYRUP, AGAVE LEMON JUICE",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/07 Zaatar.jpg"
    },

    {
        name: "OLD PORT , ",
        details: " SELTZER STRAWBERRY",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/08 Old Port.jpg"
    },

    {
        name: "SPIRITS , ",
        details: " SELTZER ITALIAN SPRITZ",
        price: "55",
        img_path: "/baron-menu-designs/Aperitif Pics/09 Sprits.jpg"
    },
]

let aperitifOneAr = [

    {
        name: "فلافي فيز، ",
        details: "ليمون، لايم، عصير كرز، برتقال سيلتزر",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/gray color.jpg",
    },

    {
        name: "روسو، ",
        details: "لندن دراي، شراب برتقال، روسو",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/01 Rosso.jpg",
    },

    {
        name: "ليمونادة، ",
        details: "شراب الشمندر والسماق، الليمون، السماق، سيلتزر",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/04 Lemonade.jpg",
    },

    {
        name: "توت اسود، ",
        details: "حليب جوز الهند، سيلتزر الفانيليا",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/03 Blackberry.jpg",
    },

    {
        name: "بيتش، ",
        details: "شراب الرمان، الورد، الليمون سيلتزر",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/06 Beach.jpg",
    },

    {
        name: "هاواي، ",
        details: "توت ازرق، ليمون، ورد، ليتشي سيلتزر",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/05 huwaii.jpg",
    },

    {
        name: "الميناء القديم، ",
        details: "سيلتزر فراولة",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/08 Old Port.jpg",
    },

    {
        name: "زعتر، ",
        details: "لندن دراي، شراب الزعتر، الصبار، عصير الليمون",
        price: "45",
        img_path: "/baron-menu-designs/Aperitif Pics/07 Zaatar.jpg",
    },

    {
        name: "مشروبات منعشة، ",
        details: "سيلتزر سبرتز الإيطالية",
        price: "55",
        img_path: "/baron-menu-designs/Aperitif Pics/09 Sprits.jpg",
    },
    
]


export {
    aperitifOne,
    aperitifOneAr,
}