import { Grid } from "@mui/material"
import { useState } from "react";
import styled from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ltb_pasta } from "../../../data/ltb-categories-details";

function PastaARisottoGrid() {
    
    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Fettuchini truffle Pasta&risotto.jpg"/>
                    <div className="dish-title">Fettuccine Truffle</div>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div onClick = {() => handleClick("1")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Lasagna Pasta&risotto.jpg"/>
                    <div className="dish-title">Lasagna</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "2" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Linguine with lobster Pasta&risotto.jpg"/>
                    <div className="dish-title">Linguine with Lobster</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("3")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Safron shrimp Pasta&risotto.jpg"/>
                    <div className="dish-title">Saffron Risotto Shrimp</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test>
            <div className = "dish-heading">
                Pasta & Risotto
            </div>
            <div className = "dish-image-container">
                <img src = {`/${ltb_pasta[showDish]}`}/>
            </div>  
            <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                <FontAwesomeIcon icon = {faXmark}/>
            </div>
        </Test>
        )
    }
}

const Test = styled.div`
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    inset:0;
    background-color: #011e41;
    z-index:1;

    img {
    }

    @media only screen and (min-width: 500px) {
        img {
            /* max-width: 500px;
            left:20%; */
            top: -10%;
        }
    }

    
`

export default PastaARisottoGrid;