import styled from "styled-components";

function TeaGrid({}) {
    return (
        <>
            <div className="food-menu-heading">TEA</div>
            <TeaStyled>
                <div>
                    <span>BREAKFAST TEA / 17</span>
                </div>
                <div>
                    <span>GREEN TEA CHAMOMILE / 17</span>
                </div>
                <div>
                    <span>GREEN TEA / 17</span>
                </div>                
            </TeaStyled>
        </>
    )
}

function TeaGridAr({}) {
    return (
        <>
            <div className="food-menu-heading-ar">شاي</div>
                <TeaArStyled>
                    <div>
                        <span>بريكفاست تي / ١٧</span>
                    </div>
                    <div>
                        <span>شاي أخضر بابونج / ١٧</span>
                    </div>
                    <div>
                        <span>شاي أخضر / ١٧</span>
                    </div>                
                </TeaArStyled>
        </>
    )
}

const TeaStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

const TeaArStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`
export {
    TeaGrid,
    TeaGridAr,
}