let juices = [

    {
        name: "JUICE ",
        details: " YOUR SELECTION",
        price: "45",
        img_path: "/baron-menu-designs/Beverages Pics/01 juices.jpg"
    },

    {
        name: "AQUA PANNA ",
        details: " 0.5L - 1L",
        price: "18 | 30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "SAN PELLEGRINO ",
        details: " 0.5L - 1L",
        price: "18 | 30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "POP ",
        details: " COKE, COKE ZERO, SPRITE",
        price: "18",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "LIMONATA ",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "ARANCITA ROSSO ",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "ARANCITA ",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "GINGER ALE ",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },
]

let coffee = [

    {
        name: "RAQWA COFFEE ",
        details: "",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/01 Rakqa Coffee.jpg"
    },

    {
        name: "ESPRESSO ",
        details: "ILLY",
        price: "18",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "DBL ESPRESSO ",
        details: "ILLY",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/03 DBL Espresso.jpg"
    },

    {
        name: "AMERICANO ",
        details: "ILLY",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/04 Americano.jpg"
    },

    {
        name: "MACCHIATO ",
        details: "ILLY, MILK FOAM",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/05 Macchiato.jpg"
    },

    {
        name: "CAPPUCCINO ",
        details: "ILLY, FOAM, MILK",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/06 Cappuccino.jpg"
    },

    {
        name: "CORTADOA ",
        details: "",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/07 Cortado.jpg"
    },

    {
        name: "FLAT WHITE ",
        details: "",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "LATTE ",
        details: "CAFÉ, MATCHA, SPANISH",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/09 Ice Latte_Spanish.jpg"
    },

    {
        name: "CHOCOLATE ",
        details: "HOT",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "TEA ",
        details: "YOUR SELECTION",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "MATCHA LATTE ",
        details: "",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/12 Ice Matcha.jpg"
    },

    {
        name: "ICED COFFEE, ",
        details: "YOUR SELECTION",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/13 Ice Americano.jpg"
    },
]

//-------------------------------------- ARABIC TEXT ------------------------------------
let juicesAr = [

    {
        name: "مياه أكوا بانا ",
        details: "٥ لتر - ١ لتر",
        price: "18 | 30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },

    {
        name: "عصير ",
        details: "من اختيارك",
        price: "45",
        img_path: "/baron-menu-designs/Beverages Pics/01 juices.jpg",
    },

    {
        name: "بوب ",
        details: "كوكا كولا، كوكا زيرو، سبريت",
        price: "18",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },

    {
        name: "سان بيليجرينو ",
        details: "غازي ٠,٥ لتر - ١ لتر",
        price: "18 | 30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },

    {
        name: "أرانسيتا روسو",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },

    {
        name: "ليموناتا",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },

    {
        name: "الزنجبيل الغازي",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },

    {
        name: "ارانسيتا",
        details: "",
        price: "22",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg",
    },
]

let coffeeAr = [

    {
        name: "اسبريسو ",
        details: "ايلي",
        price: "18",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "ركوة قهوة",
        details: "",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/01 Rakqa Coffee.jpg"
    },

    {
        name: "أمريكانو ",
        details: "إيلي",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/04 Americano.jpg"
    },

    {
        name: "دبل اسبريسو ",
        details: "ايلي",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/03 DBL Espresso.jpg"
    },

    {
        name: "كابتشينو إيلي، ",
        details: "رغوة الحليب، حليب",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/06 Cappuccino.jpg"
    },

    {
        name: "ماكياتو",
        details: "إيلي، رغوة الحليب",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/05 Macchiato.jpg"
    },

    {
        name: "فلات وايت",
        details: "",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "كورتادوا",
        details: "",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/07 Cortado.jpg"
    },

    {
        name: "الشوكولاتة ",
        details: "ساخنة",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "لاتيه، ",
        details: "كافيه، ماتشا، إسباني",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/09 Ice Latte_Spanish.jpg"
    },

    {
        name: "ماتشا لاتيه ",
        details: "",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/12 Ice Matcha.jpg"
    },

    {
        name: "الشاي ",
        details: "من اختيارك",
        price: "25",
        img_path: "/baron-menu-designs/Beverages Pics/gray color.jpg"
    },

    {
        name: "قهوة مثلجة، ",
        details: "من اختيارك",
        price: "30",
        img_path: "/baron-menu-designs/Beverages Pics/13 Ice Americano.jpg"
    },


]


export {
    juices,
    coffee,
    juicesAr,
    coffeeAr,
}