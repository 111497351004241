import {useState} from "react";
import { Document, Page} from "react-pdf";
import styled from "styled-components";

function FiveAm({}) {



    return (
        <>
            <PageStyled>
                <iframe
                    src = "https://drive.google.com/file/d/1vFTjHQ3oUaUMnPTB5U89WsYxjOVE0NZW/preview"
                    width = "100%"
                    height = "100%"
                    allow = "autoplay"
                />
            </PageStyled>
        </>
    )
}

const PageStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
`

export default FiveAm;