import { Grid } from "@mui/material";
import styled from "styled-components";

function BeveragesGrid({}) {
    return (
        <>
            <div className="food-menu-heading">BEVERAGES</div>
            <Grid container spacing = {2} rowSpacing = {4}>
            <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Beverages JPG/Beverages-04.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Beverages JPG/Beverages-02.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Beverages JPG/Beverages-03.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Beverages JPG/Beverages-05.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Beverages JPG/Beverages-06.jpg"/>
                    </div>
                </Grid>
            </Grid>
             <div style = {{fontSize: "16px"}} className="food-menu-heading">COFFEE (HOT/COLD)</div>
            <Grid container spacing = {2} rowSpacing = {4}>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/coffee-latte.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/coffee-cappuccino.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/coffee-americano.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/coffee-cafe con leche.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-03.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-05.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-02.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-08.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-09.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-01.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-11.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/Coffee-06.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/coffee-hot chocolate.jpg"/>
                        </div>
                    </Grid>
                    <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/Coffee JPG/coffee-mocha.jpg"/>
                        </div>
                    </Grid>
            </Grid>
            <div style = {{fontSize: "16px"}} className="food-menu-heading">TEA</div>
            <TeaStyled>
                <div>
                    <span>BREAKFAST TEA / 17</span>
                </div>
                <div>
                    <span>GREEN TEA CHAMOMILE / 17</span>
                </div>
                <div style = {{marginBottom: "0px"}}>
                    <span>GREEN TEA / 17</span>
                </div>
                <div>
                    <span>ICE TEA PEACH / 22</span>
                </div>
                <div>
                    <span>ICE TEA PASSION FRUIT / 22</span>
                </div>              
            </TeaStyled>
        </>
    )
}

function BeveragesGridAr() {
    return (
        <>
            <div className="food-menu-heading-ar">المشروبات</div>
            <Grid container spacing = {2} rowSpacing = {4}>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Beverages Ar JPG/Beverages-AR-02.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Beverages Ar JPG/Beverages-AR-03.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Beverages Ar JPG/Beverages-AR-04.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Beverages Ar JPG/Beverages-AR-05.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Beverages Ar JPG/Beverages-AR-06.jpg"/>
                    </div>
                </Grid>
                
            </Grid>
            <div className="food-menu-heading-ar">قهوة (بارد - ساخن)</div>
            <Grid container spacing = {2} rowSpacing = {4}>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-04-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-12-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-10-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-07-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-Ar-03.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-Ar-05.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-Ar-02.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-Ar-08.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}> 
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-Ar-09.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-AR-01.jpg"/>
                        </div>
                </Grid>
                <Grid item xs = {4}>
                        <div>
                            <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-dabio-ar.jpg"/>
                        </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/Coffee-Ar-06.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-11-ar.jpg"/>
                    </div>
                </Grid>
                <Grid item xs = {4}>
                    <div>
                        <img style = {{width: "100%"}}  src = "/wcs-menu-designs/wcs-menu-ar/Coffee Ar JPG/coffee-14-ar.jpg"/>
                    </div>
                </Grid>
            </Grid>
            <div className="food-menu-heading-ar">شاي</div>
                <TeaArStyled>
                    <div>
                        <span>بريكفاست تي / ١٧</span>
                    </div>
                    <div>
                        <span>شاي أخضر بابونج / ١٧</span>
                    </div>
                    <div style = {{marginBottom: "0px"}}>
                        <span>شاي أخضر / ١٧</span>
                    </div> 
                    <div>
                        <span>شاي مثلج بالخوخ / ٢٢</span>
                    </div>  
                    <div>
                        <span>شاي مثلج بالباشن فروت / ٢٢</span>
                    </div>             
                </TeaArStyled>
        </>
    )
}

const QambouchaStyled = styled.div`
    margin-top: 38px;
    margin-bottom: 15px;
    font-weight: bold;
    font-style: normal;
    font-size:24px;
`

const TeaStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;

    span {
        font-size: 14px;
        font-weight: bold;
    }
`

const TeaArStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`



export {
    BeveragesGrid,
    BeveragesGridAr,
}